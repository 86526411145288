<template>
  <div class="store_container">
    <div class="store_content_banner">
      <img :src="banner" />
    </div>
    <StoreBox class="store_content_main" v-for="(v, i) in stores" :key="i" :label="v.name" :addr="v.adr" :src="v.url" />
  </div>
</template>

<script>
import { style, storebanner } from "@/mock/data";
import StoreBox from "@/component/storeBox/index.vue";

export default {
  metaInfo: {
    title: '彬茂建材-风格',
    meta: [

      {             // set meta
        name: '彬茂建材,广东彬茂,广东彬茂新型建筑材料有限公司,环保材料,建筑装修,建筑装修,环保建筑装修材料,木饰面,碳晶板,金属线条,装修设计效果图,防火防水装修材料',
        content: '我们主要使用的是竹木纤维材质，竹木纤维采用竹粉、木粉、碳粉，天然树脂 微晶颗粒技术通过高温高压压缩而成的一种新型环保装饰材料。' +
          '厂家这边也在不断的研发新型产品应用于各大领域，2023迎合市场发展需求，第九代产品 冰火板 金刚板' +
          '全铝板材全国首发上线进入市场（太空舱便捷旅馆和睡眠舱，蜗居等新型便捷住宿城市旅馆是我们的研发项目，城市外来人口越来越多，外来务工人员旅游出差人员入住方便，经济实惠，便民利民是我们主推的发展理念）'
      },
    ],
  },
  components: { StoreBox },
  methods: {},
  data() {
    return {
      banner: storebanner,
      stores: style,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.store_container {
  width: 100%;
  height: auto;
  @include flex(column, flex-start, center);

  .store_content_banner {
    width: 100%;
    background-color: #333;

    img {
      width: 100%;
    }
  }

  .store_content_main {
    width: 1440px;
    margin-bottom: 100px;

    img {
      width: 100%;
    }
  }
}
</style>