<template>
  <div class="store_box_container">
    <!-- <div class="store_box_content_info_container">
      <div class="store_box_content_info_logo">
        <img :src="chenqiaoText" />
      </div>
      <div class="store_box_content_info_main">
        <div class="store_box_content_info_main_name">{{ label }}</div>
        <div class="store_box_content_info_main_txt">
          <div class="store_box_content_info_main_txt_local">
            <img :src="storeBoxLocal" />
          </div>
          <span class="store_box_content_info_main_txt_adr">{{ addr }}</span>
          <div class="store_box_content_main_txt_qr">
            <img :src="storeBoxQr" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="store_box_content_img_container">
      <img :src="src" alt="" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: "暂无分店名",
    },
    addr: {
      type: String,
      default: "暂无地址",
    },
    src: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/global.scss";

.store_box_container {
  width: 100%;
  height: auto;
  @include flex(column, flex-start, center);
  .store_box_content_info_container {
    width: 40%;
    flex: 0 0 82px;
    @include flex(row, space-between, flex-start);
    margin: 56px 0 90px 0;
    .store_box_content_info_logo {
      flex: 0 0 142px;
      height: 27px;
      margin-top: 2px;
      img {
        width: 100%;
      }
    }

    .store_box_content_info_main {
      height: 100%;
      @include flex(column, space-between, flex-start);
      .store_box_content_info_main_name {
        @include eew(20px);
        color: #343434;
      }

      .store_box_content_info_main_txt {
        flex: 1;
        @include flex(row, flex-start, flex-end);
        flex-wrap: nowrap;
        .store_box_content_info_main_txt_local {
          flex: 0 0 16px;
          height: 19px;
          img {
            width: 100%;
          }
        }

        .store_box_content_info_main_txt_adr {
          font-size: 14px;
          color: #777;
          margin-left: 5px;
          margin-right: 31px;
        }

        .store_box_content_main_txt_qr {
          flex: 0 0 31px;
          height: 31px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .store_box_content_img_container {
    width: 100%;
    flex: 0 0 651px;
    img {
      width: 100%;
    }
  }
}
</style>